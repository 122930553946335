import React from 'react';
import store from "./store";
import history from "./history";
import { Redirect } from "react-router-dom";
import {doGet} from "./DataManager";

const getState = function() {
    return store.getState();
}

export function goTo(path) {
    return (<Redirect to={path} push />)
}

export function getGlobalState() {
    return getState();
}

export function renderIf(condition, dom) {
    if(condition) {
        return dom;
    }
    return '';
}

export async function getCompanies() {
    const snap = await window.firebase.firestore().collection('companies').where('active', '==', true).get();
    const companies = [];
    if(!snap.empty) {
        for(let doc of snap.docs) {
            const company = doc.data();
            company.id = doc.id;
            companies.push(company);
        }
    }
    return companies;
}

export function isInRole(arr) {
    const state = getState();
    if(arr && arr.indexOf(state.role)!=-1) {
        return true;
    }
    return false;
}

export function goToPath(path, state = null) {
    history.push(path, state ? state : undefined);
}

function getContratto(cid) {
    return new Promise(function(resolve) {
        if(cid) {
            window.firebase.firestore().collection('contratti').where("codice", "==", cid).get()
                .then(function(snap) {
                    if(!snap.exists) {
                        for(let doc of snap.docs) {
                            const contratto = doc.data();
                            resolve(contratto);
                            break;
                        }
                    }
                })
                .catch(function(err) {
                    console.log(err);
                    resolve(null);
                })
        } else {
            resolve(null);
        }
    });
}

export async function getPrezzoFermoAuto(order) {
    return new Promise(async function(resolve) {
        let prezzo = 0;
        if(order.fermo_auto) {
            try {
                const contratto = await getContratto(order.contratto);
                if(contratto && contratto.durata_inclusa) { //Senza contratto non saprei come prezzarlo
                    const durata = order.tempo;
                    //if(durata>contratto.durata_inclusa) {
                        //const rimanenza = durata - contratto.durata_inclusa;
                        const slot_da_pagare = Math.ceil(order.fermo_auto/30);
                        const prezzo_slot = 17.5;
                        prezzo = slot_da_pagare * prezzo_slot;
                    //}
                }
            } catch(err) {
                console.log(err);
                resolve(prezzo);
            }
        }
        resolve(prezzo);
    })
}

export async function getTripData(id) {
    return new Promise(async function(resolve, reject) {
        if(id && id!=="") {
            doGet("/orders/getOrder", {oid: id})
                .then((order) => {
                    resolve(order)
                })
                .catch((err) => {
                    reject(err);
                })
        } else {
            reject('id mancante');
        }
    });
}

export function formatDailyOrders(orders) {
    const groups = {};
    for(let o of orders) {
        const id = o.hasOwnProperty('group_id') ? o.group_id : 'no_group';
        if(id == 'no_group') {
            o.isGroup = false;
        }
        if(!groups.hasOwnProperty(id)) {
            groups[id] = [];
        }
        groups[id].push(o);
    }
    const defOrders = groups['no_group'] || [];
    delete groups['no_group'];
    for(let k of Object.keys(groups)) {
        const group = groups[k];
        group.sort((a, b) => {
            const aStart = parseInt(a.orario_presa.replace(":", ""));
            const bStart = parseInt(b.orario_presa.replace(":", ""));
            if(aStart > bStart) {
                return 1;
            } else {
                return -1;
            }
        });
        const ids = group.map((o) => {
            return o.id;
        })
        const passeggero = group.map((so) => {
            return so.nome_passeggero;
        }).join(", ")
        const prezzo = group.map((so) => {
            let p;
            if(so.prezzo) {
                p = so.prezzo;
                if(typeof p == 'string') {
                    p.replace("€", "").trim()
                }
            }
            return parseFloat(p || 0);
        }).reduce((partialSum, a) => partialSum + a, 0) + " €";
        const mergedOrder = {
            "isGroup": true,
            "prezzo": prezzo,
            "source": "MBILITY",
            "nome_passeggero": passeggero,
            "orario_presa": group[0].orario_presa,
            "attivo": true,
            "partenza": group[0].partenza,
            "fine": group[0].fine,
            "giorni": group[0].giorni,
            "arrivo": group[0].arrivo,
            "id": k,
            "group_id": group[0].group_id,
            "ids": ids
        }
        if(group[0].company) {
            mergedOrder.company = group[0].company
        }
        if (group[0].autista){
            mergedOrder.autista = group[0].autista
        }
        defOrders.push(mergedOrder);
    }
    return defOrders;
}

export function formatOrders(orders) {
    const groups = {};
    for(let o of orders) {
        const id = o.hasOwnProperty('group_id') ? o.group_id : 'no_group';
        if(id == 'no_group') {
            o.isGroup = false;
        }
        if(!groups.hasOwnProperty(id)) {
            groups[id] = [];
        }
        groups[id].push(o);
    }
    const defOrders = groups['no_group'] || [];
    delete groups['no_group'];
    for(let k of Object.keys(groups)) {
        const group = groups[k];
        group.sort((a, b) => {
            const aDate = a.data_esecuzione.toDate();
            const bDate = b.data_esecuzione.toDate();
            if(aDate > bDate) {
                return 1;
            } else {
                return -1;
            }
        });
        const ids = group.map((o) => {
            return o.id;
        })
        const status = group[0].status; //Prendo il primo ordine perchè dovrebbero avere tutti lo stesso
        const passeggero = group.map((so) => {
            return so.passeggero || (so.nome + ' ' + so.cognome);
        }).join(", ")
        const distanza = group.map((so) => {
            return parseFloat(so.distanza_num || 0);
        }).reduce((partialSum, a) => partialSum + a, 0) + "km";
        const prezzo = group.map((so) => {
            let p;
            if(so.prezzo) {
                p = so.prezzo;
                if(typeof p == 'string') {
                    p.replace("€", "").trim()
                }
            }
            return parseFloat(p || 0);
        }).reduce((partialSum, a) => partialSum + a, 0) + " €";
        const mergedOrder = {
            id: k,
            status: status,
            isGroup: true,
            indirizzo_da: group[0].indirizzo_da,
            indirizzo_a: group[group.length-1].indirizzo_a,
            data_esecuzione: group[0].data_esecuzione,
            data_inserimento: group[0].data_inserimento,
            passeggero: passeggero,
            company: group[0].company,
            source: 'GRUPPO',
            prezzo: prezzo,
            distanza: distanza,
            ar: false,
            ids: ids
        };
        if(group[0].company) {
            mergedOrder.company = group[0].company
        }
        if(group[0].id_autista) {
            mergedOrder.id_autista = group[0].id_autista
        }
        defOrders.push(mergedOrder);
    }
    return defOrders;
}

export function getIdGestore(company) {
    return new Promise((resolve) => {
        if(company) {
            window.firebase.firestore().collection('gestori').where('company', '==', company).get()
                .then((snap) => {
                    if(!snap.empty) {
                        const doc = snap.docs[0];
                        resolve(doc.id);
                    } else {
                        resolve(null);
                    }
                })
                .catch(() => {
                    resolve(null);
                })
        } else {
            resolve(null);
        }
    })
}